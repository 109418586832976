import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { SEO, Card } from '@components';

import './blog-tags.scss';

const Tags = ({ pageContext, data }) => {
  const currentTag = pageContext.tag;
  const { edges, totalCount } = data.allMarkdownRemark;
  const tagHeader = `${totalCount} post${totalCount === 1 ? '' : 's'} tagged with "${currentTag}"`;

  return (
    <div>
      <SEO title="Blog" />
      <div className="on-tags-main">
        <h2>{tagHeader}</h2>
        {edges.map(({node}) => (
          <div key={node.fields.slug}>
            <Link className="on-tags-item" to={node.fields.slug}>
              <Card className="on-normal">
                <div className="on-tags-item-grid">
                  <h3>
                    {node.frontmatter.title}{' '}
                    <span>— {node.frontmatter.date}</span>
                  </h3>
                  <p>{node.excerpt}</p>
                </div>
              </Card>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allGithubFile: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired,
      ),
    }),
  }),
};

export const pageQuery = graphql`
  query($tag: String!) {
    ...BlogTagsQueryFragment
  }
`;
export default memo(Tags);
